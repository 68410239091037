<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="培训计划">
              <a-select
                show-search
                v-model="queryParams.trainingId"
                placeholder="选择培训计划"
              >
                <a-select-option
                  v-for="(r, index) in trainingData"
                  :value="r.id"
                  :label="r.title"
                  :key="index"
                  >{{ r.title }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="员工姓名">
              <a-input v-model="queryParams.username" />
            </a-form-item>
          </a-col> 
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button
          style="margin-left: 50px"
          @click="exportExcel"
          >导出成绩
        </a-button>
        <a-upload
          :fileList="fileList"
          :remove="handleRemove"
          :disabled="fileList.length === 1"
          :beforeUpload="beforeUpload"
        >
          <a-button> <a-icon type="upload" /> 选择.xlsx文件 </a-button>
          <a-button
            class="button-area"
            @click="handleUpload"
            :disabled="fileList.length === 0"
            :loading="uploading"
          >
            {{ uploading ? "导入成绩中" : "导入成绩" }}
          </a-button>
        </a-upload>
      </div>
      <a-table
        :data-source="dataSource"
        :columns="columns"
        :rowKey="(record) => record.userId"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template slot="operation" slot-scope="text, record">
          <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="clickEdit(record)" title="成绩录入"></a-icon>
        </template>
      </a-table>
      <course-result
        ref="courseResult"
        @success="handleSuccess"
      >
      </course-result>
    </div>
  </a-card>
</template>
<script>
import CourseResult from "@/views/training/CourseResult";
import { mapState } from "vuex";

export default {
  name: "trainingUser",
  components: { CourseResult },
  data() {
    return {
      dataSource: [],
      fileList: [],
      uploading: false,
      selectedRows: [],
      columns: [
        {
          title: "姓名",
          dataIndex: "username",
          width: 200,
          key: "username",
        },
        {
          title: "员工编号",
          dataIndex: "empno",
          width: 200,
          key: "empno",
        },
        {
          title: "所在部门",
          width: 200,
          dataIndex: "deptName",
        },
        {
          title: "得分",
          width: 200,
          dataIndex: "score",
        },
        {
          title: "是否完成",
          width: 200,
          dataIndex: "status",
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy
              if (text === key) {
                let val = this.status[_index].valuee
                let color = this.status[_index].otherKeyy
                return <a-tag color={color}>{val}</a-tag>
              } else {
                continue
              }
            }
            return text
          },
        },
        {
          title: "未完成原因",
          width: 400,
          dataIndex: "reason",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 100,
        },
      ],
      loading: false,
      queryParams: {
        trainingId: undefined,
        username: undefined,
      },
      currentTraining: undefined,
      trainingData: [],
      allUser: [],
      trainingResults: [],
      courseResult: [],
      pagination: {
        pageSizeOptions: ["1", "10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: 0,
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
        onChange: (current, pageSize) => {
          this.pagination.current = current;
          this.pagination.pageSize = pageSize;
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
        },
      },
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.training_user_status,
    }),
  },
  mounted() {
    this.$get("training/all-list").then((r) => {
      this.trainingData = r.data.data;
      if(this.$route.query.trainingId!=undefined) {
        this.queryParams.trainingId = Number(this.$route.query.trainingId);
      }
      this.search();
    });    
  },
  methods: {
    handleRemove(file) {
      if (this.uploading) {
        this.$message.warning("文件导入中，请勿删除");
        return;
      }
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      formData.append("file", fileList[0]);
      this.uploading = true;
      this.$upload("training-user/import", formData)
        .then((r) => {
          let data = r.data.data;
          if (data.data != undefined && data.data.length) {
            this.fetch();
          }
          this.importData = data.data == undefined ? [] : data.data;
          this.errors = data.error == undefined ? [] : data.error;
          this.times = data.time / 1000;
          this.uploading = false;
          this.fileList = [];
          this.importResultVisible = true;
        })
        .catch((r) => {
          console.error(r);
          this.uploading = false;
          this.fileList = [];
        });
    },
    exportExcel() {
      this.$export("training-user/excel?trainingId=" + this.queryParams.trainingId);
    },
    reset() {
      this.pagination.current = 1;
      // 取消选中
      this.loading = false;
      this.queryParams.trainingId = undefined;
      this.queryParams.username = undefined;
      this.allUser = [];
      this.dataSource = [];
    },
    handleTableChange(pagination, filters, sorter) {
      this.fetch();
    },
    fetch() {
      this.loading = true;
      this.queryParams.pageSize = this.pagination.pageSize;
      this.queryParams.pageNum = this.pagination.current;

      this.$get("training-user", {
        ...this.queryParams,
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.dataSource = data.rows
        this.pagination = pagination
      });
    },
    search() {
      this.fetch();
    },
    clickEdit(record) {
      this.$refs.courseResult.setForm(record, [record]);
    },
    handleSuccess() {
      this.search();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../utils/Common.less";
</style>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
